import { FunctionComponent, useState, useEffect, lazy, Suspense } from 'react'
import {
  IProps,
  ICoordinates,
  IMapInstance,
  IStreetMapData,
} from './interfaces'
import { useLocation } from 'react-router-dom'
import { ISearchQuery } from '../common-ui/interfaces'
import queryString from 'query-string'
import { ChangeEventValue, Maps } from 'google-map-react'
import clamp from 'lodash.clamp'
import { getDistance, requiresRequery } from './QueryBounds'
import { MAX_RADIUS, MIN_RADIUS } from '../../constants/radiusConstants'
import {
  IEntityType,
  IListingTypeID,
  IMHBOListing,
  ISellerTypeID,
} from 'mhbo-js'
import Legend from './Legend'
import createGTM from '../../helpers/gtmevent'
import GoogleMapReact from 'google-map-react'
import CommunityMarker from './CommunityMarker'
import CircularMarker from './CircularMarker'
import useSupercluster from 'use-supercluster'
import ClusterMarker from './ClusterMarker'
import GOOGLE_API from '../../constants/googleAPIConstants'

const Rollover = lazy(() => import('./Rollover/index'))

const PropertiesMap: FunctionComponent<IProps> = ({
  isFirstLoad,
  streetMapData,
  updateRequeryStatus,
  updateMapAxisAlignedBoundingBox,
  lastCenterLat,
  lastCenterLng,
  lastCenterRadius,
  listingsSearch,
  updateLatLng,
  removeSelectedDetail,
  updateSelectedDetail,
  updateInitialSelectedLocation,
  updateSelectedLocation,
  selectedLocation,
  shouldRequery,
  boundslistings,
  filtered,
  updateBoundaryFilter,
  triangle,
  updateFirstLoad,
  updateTriangle,
  showMap,
}) => {
  const [center, setCenter] = useState<ICoordinates>({ lat: 39, lng: 65 })
  const [map, setMap] = useState<IMapInstance>({
    map: null,
    maps: null,
    ref: null,
  })
  const [zoom, setZoom] = useState<number>(5)
  // console.log({ zoom })
  // todo use bounds from store instead of extra state in map
  const [bounds, setBounds] = useState<number[]>([])
  // move filtered and to store and filter bounds listings there directly
  const points = boundslistings.map((listing: IMHBOListing) => ({
    type: 'Feature',
    properties: { cluster: false, listing },
    geometry: {
      type: 'Point',
      coordinates: [listing.address.longitude, listing.address.latitude],
    },
  }))

  const [lastClickedClusterDetails, setLastClickedClusterDetails] = useState({
    clusterId: null,
    pointCount: 0,
    zoom: -1,
  })

  const [childmarkers, setchildmarkers] = useState([])

  const location = useLocation()

  const currentQuery: ISearchQuery = queryString.parse(location.search)

  // -81.9542144, 28.883151, -81.891199, 28.960001
  // -81.99652109208984, 28.803568134731123, -81.84889230791015, 29.039463948536834
  // 4, 2, 3, 1
  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: {
      radius: 125,
      maxZoom: 23,
      minZoom: 0,
      minPoints: 5,
      nodeSize: 64,
    },
  })

  const createPolygon = (
    coordinates: number[][][],
    mapObject: IMapInstance
  ) => {
    const { maps, map } = mapObject
    const trianglepaths: any[] = []
    for (const trianglecoords of coordinates) {
      const boundarypath = []
      for (const point of trianglecoords) {
        boundarypath.push({ lng: point[0], lat: point[1] })
      }
      trianglepaths.push(boundarypath)
    }
    const polygonObject = new maps.Polygon({
      fillColor: 'red',
      fillOpacity: 0.1,
      paths: trianglepaths,
      strokeColor: 'red',
      strokeOpacity: 0.8,
      strokeWeight: 2,
    })
    polygonObject.setMap(map)
    return polygonObject
  }

  const nominatimQuery = (
    streetMapData: IStreetMapData,
    mapsfromprop: IMapInstance | null = null
  ) => {
    updateRequeryStatus(false)
    if (streetMapData.boundingbox) {
      const { boundingbox, type, coordinates } = streetMapData

      let googlemapsstate: IMapInstance | null = map.map ? map : mapsfromprop
      if (googlemapsstate) {
        const { map: mapInstance, maps } = googlemapsstate

        const { defaultRadius, selectedEntityId } = currentQuery
        if (!(selectedEntityId && isFirstLoad)) {
          const bounds = new google.maps.LatLngBounds(
            new maps.LatLng(boundingbox[0], boundingbox[2]),
            new maps.LatLng(boundingbox[1], boundingbox[3])
          )

          const centerSfo = bounds.getCenter()

          mapInstance.setCenter(centerSfo)
          if (defaultRadius) {
            const circle = new google.maps.Circle({
              radius: parseInt(defaultRadius) * 1609.34,
              center: centerSfo,
            })
            const circlebounds = circle.getBounds()
            if (circlebounds) {
              bounds.extend(circlebounds.getNorthEast())
              bounds.extend(circlebounds.getSouthWest())
            }
          }
          mapInstance.panToBounds(bounds)
          mapInstance.fitBounds(bounds)
        }
        window.addEventListener(
          'idle',
          () => {
            window.addEventListener('resize', () => {
              mapInstance.fitBounds(bounds)
            })
          },
          { passive: true }
        )
        if (type === 'Polygon' || type === 'MultiPolygon') {
          const trianglearray = []
          if (type === 'Polygon') {
            if (coordinates.length > 0) {
              trianglearray.push(
                createPolygon(coordinates as number[][][], googlemapsstate)
              )
            }
          } else {
            if (coordinates.length > 0) {
              for (const polygon of coordinates as number[][][][]) {
                trianglearray.push(createPolygon(polygon, googlemapsstate))
              }
            }
          }
          updateTriangle(trianglearray)
        }
      }
    }
  }

  const onGoogleApiLoaded = (maps: IMapInstance) => {
    try {
      setMap(maps)
      maps.map.data.setStyle({
        fillColor: '#91278f',
        fillOpacity: 0.1,
        strokeColor: '#91278f',
        strokeOpacity: 0.8,
        strokeWeight: 2,
      })
    } catch (error) {
      console.log({ error })
      alert('Error loading google maps. Please try again later')
    }
  }

  const performSearch = (lat: number, lng: number, radius = MIN_RADIUS) => {
    const newParams: any = {
      isDealer: 'false',
      ...currentQuery,
      location: `${lat},${lng}`,
      page: 1,
      pageCount: 500,
      radius,
      sellerTypeIds: [
        `${ISellerTypeID.Owner}`,
        `${ISellerTypeID.Repo}`,
        `${ISellerTypeID.Agent}`,
      ],
    }
    listingsSearch({ params: newParams })
    updateLatLng({ lat, lng })
  }

  const onMapChange = (params: ChangeEventValue) => {
    try {
      const { bounds, center, zoom } = params
      console.log({ zoom })
      setZoom(zoom)
      setCenter(center)
      setchildmarkers([])
      setBounds([bounds.nw.lng, bounds.se.lat, bounds.se.lng, bounds.nw.lat])
      if (map.map) {
        const { maps } = map

        const NE = new maps.LatLng(bounds.ne.lat, bounds.ne.lng)
        const SW = new maps.LatLng(bounds.sw.lat, bounds.sw.lng)
        const centerLatLng = new google.maps.LatLng(center.lat, center.lng)

        updateMapAxisAlignedBoundingBox({
          SWLat: SW.lat().toString(),
          SWLng: SW.lng().toString(),
          NELat: NE.lat().toString(),
          NELng: NE.lng().toString(),
        })
        const lastQuery = {
          center: {
            lat: lastCenterLat,
            lng: lastCenterLng,
          },
          radius: lastCenterRadius,
        }
        if (requiresRequery({ lastQuery, boundingBox: { NE, SW }, maps })) {
          const radiusNew = clamp(
            getDistance(centerLatLng, NE, maps) * 5,
            MIN_RADIUS,
            MAX_RADIUS
          )
          console.log({ radiusNew })
          performSearch(centerLatLng.lat(), centerLatLng.lng(), radiusNew)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getMapOptions = (maps: Maps) => {
    return {
      gestureHandling: 'greedy',
      mapTypeControl: true,
      mapTypeControlOptions: {
        mapTypeIds: [maps.MapTypeId.ROADMAP, maps.MapTypeId.SATELLITE],
        position: maps.ControlPosition.TOP_LEFT,
        style: maps.MapTypeControlStyle.DEFAULT,
      },
    }
  }

  const checkForPolygonLines = (shouldRequery: boolean, streetMapData: any) => {
    if (shouldRequery && map) {
      if (triangle.length > 0) {
        if (triangle.length > 0) {
          triangle.forEach((e: any) => e.setMap(null))
          updateTriangle([])
        }
      }

      nominatimQuery(streetMapData)
    }
  }

  const getScreenPoint = (listing: IMHBOListing) => {
    const { map: mapInstance, maps } = map
    const numTiles = 1 << mapInstance.getZoom()
    const projection = mapInstance.getProjection()
    const worldCoordinate = projection.fromLatLngToPoint(
      new maps.LatLng(listing.address.latitude, listing.address.longitude)
    )
    const pixelCoordinate = new maps.Point(
      worldCoordinate.x * numTiles,
      worldCoordinate.y * numTiles
    )

    const topLeft = new maps.LatLng(
      mapInstance.getBounds().getNorthEast().lat(),
      mapInstance.getBounds().getSouthWest().lng()
    )

    const topLeftWorldCoordinate = projection.fromLatLngToPoint(topLeft)
    const topLeftPixelCoordinate = new maps.Point(
      topLeftWorldCoordinate.x * numTiles,
      topLeftWorldCoordinate.y * numTiles
    )

    return new maps.Point(
      pixelCoordinate.x - topLeftPixelCoordinate.x,
      pixelCoordinate.y - topLeftPixelCoordinate.y
    )
  }

  const createMarker = (listing: {
    askingPrice?: any
    rentalPrice?: any
    id?: any
    address?: any
    price?: any
    entityType?: any
    listingTypeId?: any
    name?: any
  }) => {
    if (map) {
      const { map: mapInstance } = map
      let { price } = listing
      const { entityType, listingTypeId, name } = listing
      if (!price) {
        if (listingTypeId === IListingTypeID.ForSale)
          price = listing.askingPrice
        else price = listing.rentalPrice
      }
      let hexCode = '#e2610a'

      let formattedPrice = `${price}`
      if (price) {
        if (price > 1000) formattedPrice = `${(price! / 1000).toFixed(0)}K`
      }

      let text = `${formattedPrice}/month`
      let color = 'Rent'
      if (entityType === IEntityType.Community) {
        color = 'Community'
        hexCode = '#91278f'
        if (name) {
          text = name
          if (name.length > 9) {
            text = name.replace(/ .*/, '')
            if (text.length < 4 || text.length > 9) {
              text = name.substring(0, 9)
            }
            text += '...'
          }
        }
      } else if (entityType === IEntityType.Dealer) {
        color = 'Dealer'
        hexCode = '#b20404'
        if (name) {
          text = name
          if (name.length > 9) {
            text = name.replace(/ .*/, '')
            if (text.length < 4 || text.length > 9) {
              text = name.substring(0, 9)
            }
            text += '...'
          }
        }
      } else if (listingTypeId === IListingTypeID.ForSale) {
        hexCode = '#22863b'
        color = 'Sale'
        text = `$${formattedPrice}`
      }
      let { latitude, longitude } = listing.address
      if (mapInstance.zoom < 16) {
        let iconsize = 40
        if (IEntityType.Community === entityType) {
          iconsize = 45
        }
        return (
          <CircularMarker
            key={listing.id}
            color={hexCode}
            lat={latitude}
            lng={longitude}
            size={iconsize}
            onMouseOver={() => {
              updateFirstLoad(false)
              updateSelectedLocation(listing.id)
              setchildmarkers([])
            }}
            addHover={selectedLocation === listing.id}
            onClick={(e) => {
              e.stopPropagation()
              if (entityType === IEntityType.Community) {
                createGTM({
                  action: 'click, on map purple map marker (community)',
                })
              }
              updateFirstLoad(false)
              updateSelectedLocation(listing.id)
              setchildmarkers([])
              console.log('clicked', listing.id)
            }}
          />
        )
      }
      return (
        <CommunityMarker
          key={listing.id}
          color={color}
          lat={latitude}
          lng={longitude}
          text={text}
          onMouseOver={() => {
            updateFirstLoad(false)
            updateSelectedLocation(listing.id)
            setchildmarkers([])
          }}
          addHover={selectedLocation === listing.id}
          onClick={(e: any) => {
            e.stopPropagation()
            if (entityType === IEntityType.Community) {
              createGTM({
                action: 'click, on map purple map marker (community)',
              })
            }
            updateFirstLoad(false)
            updateSelectedLocation(listing.id)
            setchildmarkers([])
          }}
        />
      )
    }
    return null
  }

  useEffect(() => {
    if (childmarkers.length > 0) {
      updateSelectedLocation(childmarkers[0])
    }
  }, [childmarkers, updateSelectedLocation])

  useEffect(() => {
    try {
      if (map.maps) {
        checkForPolygonLines(shouldRequery, streetMapData)
      }
    } catch (error) {}
  }, [shouldRequery, streetMapData, map.maps])

  useEffect(() => {
    const listing = boundslistings.filter((e) => e.id === selectedLocation)
    if (listing.length > 0 && map.map) {
      if (childmarkers.length > 0 && childmarkers[0] !== listing[0].id) {
        const listingNew = boundslistings.filter(
          (e) => e.id === childmarkers[0]
        )

        const point = getScreenPoint(listingNew[0])

        updateSelectedDetail({
          detailId: listing[0].id,
          x: point && point.x ? point.x : 0,
          y: point && point.y ? point.y : 0,
        })
      } else {
        const point = getScreenPoint(listing[0])

        updateSelectedDetail({
          detailId: listing[0].id,
          x: point && point.x ? point.x : 0,
          y: point && point.y ? point.y : 0,
        })
      }
    }
    if (selectedLocation === -1) setchildmarkers([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocation, map.map, center])

  useEffect(() => {
    setchildmarkers([])
    console.log({ isFirstLoad, length: boundslistings.length })

    const { selectedEntityId, selectedEntityType } = currentQuery
    if (selectedEntityId && selectedEntityType) {
      const intSelectedEntityId = parseInt(selectedEntityId)
      console.log(boundslistings.length)
      if (isFirstLoad) {
        if (boundslistings.length === 0) {
          // get marker and add
          updateInitialSelectedLocation({
            selectedLocation: intSelectedEntityId,
            selectedEntityType: parseInt(selectedEntityType),
          })
        } else if (boundslistings[0].id === intSelectedEntityId) {
          if (map.map) {
            map.map.setCenter({
              lat: boundslistings[0].address.latitude,
              lng: boundslistings[0].address.longitude,
            })
            map.map.setZoom(16)
          }
        }
      }
    }
  }, [
    boundslistings.length,
    isFirstLoad,
    currentQuery.selectedEntityId,
    currentQuery.selectedEntityType,
    map.map,
  ])

  return (
    <div
      className="container__mapWrapper"
      onClick={() => {
        removeSelectedDetail()
        updateSelectedLocation(-1)
        updateFirstLoad(false)
      }}
      onTouchEnd={() => {
        console.log('on touch end')
        removeSelectedDetail()
        updateSelectedLocation(-1)
        updateFirstLoad(false)
      }}
    >
      {streetMapData.boundingbox || currentQuery.location === '' ? (
        <>
          <div className="legend__container">
            <div style={{ display: 'flex', justifyContent: 'end' }}>
              <Legend />
            </div>
            <div
              className="boundary_toggle__wrapper"
              onClick={(e) => {
                e.stopPropagation()
                updateBoundaryFilter(!filtered)
              }}
            >
              {`${filtered ? 'Near' : 'Only'} ${currentQuery.location}`}
            </div>
          </div>
          <Suspense fallback={null}>
            <Rollover childmarkers={childmarkers} />
          </Suspense>
          <GoogleMapReact
            bootstrapURLKeys={{
              key: GOOGLE_API.apiKey,
              libraries: ['places', 'geometry'],
              id: 'MainSearchFieldAPI',
            }}
            center={center}
            zoom={zoom}
            yesIWantToUseGoogleMapApiInternals={true}
            onGoogleApiLoaded={onGoogleApiLoaded}
            onChange={onMapChange}
            options={getMapOptions}
            onMapTypeIdChange={(props: string) => {
              createGTM({
                action: `click, "${props === 'roadmap' ? 'map' : props}"`,
              })
            }}
          >
            {clusters.map((cluster) => {
              const [longitude, latitude] = cluster.geometry.coordinates
              const {
                cluster_id: clusterId,
                cluster: isCluster,
                point_count: pointCount,
                listing,
              } = cluster.properties

              if (isCluster) {
                return (
                  <ClusterMarker
                    key={`cluster-${cluster.id}`}
                    lat={latitude}
                    lng={longitude}
                    pointCount={pointCount}
                    onClick={(e) => {
                      e.stopPropagation()
                      updateFirstLoad(false)
                      console.log({
                        clusterId,
                        lastClicked: lastClickedClusterDetails.clusterId,
                      })
                      if (
                        lastClickedClusterDetails.clusterId === clusterId &&
                        (zoom > lastClickedClusterDetails.zoom ||
                          zoom === 22) &&
                        pointCount === lastClickedClusterDetails.pointCount &&
                        childmarkers.length === 0
                      ) {
                        console.log('enter if')
                        const childmarkers = supercluster.getChildren(clusterId)

                        if (
                          childmarkers.filter(
                            (e: { properties: { cluster: any } }) =>
                              e.properties.cluster
                          ).length === 0
                        ) {
                          setchildmarkers(
                            childmarkers.map(
                              (e: { properties: { listing: { id: any } } }) =>
                                e.properties.listing.id
                            )
                          )
                        } else {
                          if (childmarkers.length > 0) setchildmarkers([])

                          setLastClickedClusterDetails({
                            clusterId,
                            pointCount,
                            zoom,
                          })
                          setZoom(zoom + 1)
                          setCenter({ lat: latitude, lng: longitude })
                        }
                      } else {
                        console.log('enter else')
                        console.log({ length: childmarkers.length })
                        if (childmarkers.length > 0) {
                          setchildmarkers([])
                          removeSelectedDetail()
                          updateSelectedLocation(-1)
                        } else {
                          console.log('enter else else')
                          setLastClickedClusterDetails({
                            clusterId,
                            pointCount,
                            zoom,
                          })
                          setZoom(zoom + 1)
                          setCenter({ lat: latitude, lng: longitude })
                        }
                      }
                    }}
                  />
                )
              }
              return createMarker(listing)
            })}
          </GoogleMapReact>
        </>
      ) : (
        <div
          style={{
            width: '100%',
            height: '100%',
            textAlign: 'center',
            padding: '300px 0',
          }}
        >
          {currentQuery.location
            ? `Loading listings near ${currentQuery.location

                .replace(', USA', '')
                .replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())}
          `
            : ''}
        </div>
      )}
    </div>
  )
}

export default PropertiesMap
